import {
  CarOutlined,
  FileOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, message, Popconfirm, Space } from "antd";
import locale from "antd/lib/locale/vi_VN";
import { Formik } from "formik";
import {
  Checkbox,
  DatePicker,
  TimePicker,
  Form,
  FormItem,
  Input,
  SubmitButton,
} from "formik-antd";
import * as Yup from "yup";
import axiosClient from "../../network/RestClient";

const { TextArea } = Input;

export default function DetailPage({ form, model, onDone, onRemove }) {
  const create = async function (values, actions) {
    actions.setSubmitting(true);
    try {
      const res = await axiosClient.post("/agency/bill/create", {
        customerName: values.customerName || "",
        customerPhone: values.customerPhone || "",
        carPlate: values.carPlate?.toUpperCase() || "Chưa nhập",
        fromDate: values.fromDate || Date.now(),
        toDate: null,
        serviceCar: values.serviceCar || false,
        serviceBot: values?.serviceBot || false,
        note: values.note || "",
        status: values.status || "active", // active/ done
      });
      if (res && res.status === 0) {
        onDone();
      } else {
        message.error(res.message ?? "Không thể cập nhật");
      }
    } catch (err) {
      message.error(err.message);
    }
    actions.setSubmitting(false);
  };

  const update = async function (values, actions) {
    actions.setSubmitting(true);
    try {
      const res = await axiosClient.post("/agency/bill/update", {
        id: model?._id,
        customerName: values.customerName || "",
        customerPhone: values.customerPhone || "",
        carPlate: values.carPlate?.toUpperCase() || "Chưa nhập",
        fromDate: values.fromDate || Date.now(),
        serviceCar: values.serviceCar || false,
        serviceBot: values?.serviceBot || false,
        note: values.note || "",
        status: values.status || "active", // active/ done
      });
      if (res && res.status === 0) {
        onDone();
      } else {
        message.error(res.message ?? "Không thể cập nhật");
      }
    } catch (err) {
      message.error(err.message);
    }
    actions.setSubmitting(false);
  };

  const remove = async function () {
    try {
      const res = await axiosClient.get("/agency/bill/delete", {
        params: {
          id: model?._id,
        },
      });
      if (res && res.status === 0) {
        onRemove();
      } else {
        message.error(res.message ?? "Không thể xóa");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <div>
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Formik
          initialValues={{
            customerName: model?.customerName || "",
            customerPhone: model?.customerPhone || "",
            carPlate: model?.carPlate || "",
            // fromTime: new Date(model?.fromTime || Date.now()).getHours(),
            fromDate: model?.fromDate || Date.now(),
            toDate: model?.toDate || null,
            serviceCar: model?.serviceCar || false,
            serviceBot: model?.serviceBot || false,
            note: model?.note || "",
            status: model?.status || "active", // active / done
          }}
          onSubmit={(values, actions) => {
            message.info(values);
            if (model) {
              update(values, actions);
            } else {
              create(values, actions);
            }
          }}
          validationSchema={Yup.object({
            carPlate: Yup.string()
              .min(4, "Nhập biển số xe")
              .required("Bắt buộc nhập"),
          })}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          <Form>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <FormItem
                name="carPlate"
                required={true}
                style={{ padding: 0, margin: 0 }}
                normalize={(input) => input.toUpperCase()}
              >
                <Input
                  name="carPlate"
                  size="middle"
                  placeholder="Biển số xe"
                  prefix={<CarOutlined />}
                  allowClear={true}
                  autoCapitalize={true}
                  style={{
                    textTransform: "uppercase",
                    width: "100%",
                  }}
                />
              </FormItem>
              <Space
                direction="horizontal"
                size="middle"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TimePicker
                  name="fromDate"
                  locale={locale}
                  format="HH:mm"
                  style={{ width: "100%", marginTop: 0 }}
                  placeholder="Giờ đến"
                  inputReadOnly={true}
                  bordered="true"
                  size="middle"
                  showTime={false}
                />
                <DatePicker
                  name="fromDate"
                  locale={locale}
                  format="DD/MM/YYYY"
                  style={{ width: "100%", marginTop: 0 }}
                  placeholder="Ngày đến"
                  inputReadOnly={true}
                  bordered="true"
                  size="middle"
                  showTime={false}
                />
              </Space>

              <Space
                direction="horizontal"
                size="middle"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Checkbox name="serviceCar">Đưa đón bằng ô tô</Checkbox>
                  <Checkbox name="serviceBot">
                    Thu phí qua trạm soát vé
                  </Checkbox>
                </Space>
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {/* Trạng thái
                  <Radio.Group name="status">
                    <Space direction="vertical">
                      <Radio value={"active"}>Đang gửi xe</Radio>
                      <Radio value={"done"}>Hoàn thành</Radio>
                    </Space>
                  </Radio.Group> */}
                </Space>
              </Space>

              <TextArea
                name="note"
                size="middle"
                placeholder="Ghi chú"
                allowClear={true}
                prefix={<FileOutlined />}
                maxLength={200}
                rows={3}
              />
              <Space
                direction="horizontal"
                size="middle"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Input
                  name="customerPhone"
                  size="middle"
                  type="tel"
                  placeholder="Số điện thoại"
                  allowClear={true}
                  prefix={<PhoneOutlined />}
                />
                <Input
                  name="customerName"
                  size="middle"
                  placeholder="Tên khách"
                  allowClear={true}
                  prefix={<UserOutlined />}
                />
              </Space>

              <Space
                direction="horizontal"
                size="middle"
                align="center"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <SubmitButton size="middle" htmlType="submit">
                  Lưu lại
                </SubmitButton>
                <Popconfirm
                  title="Có chắc chắn xóa?"
                  onConfirm={() => remove()}
                >
                  <Button size="middle" htmlType="button" danger={true}>
                    Xóa bỏ
                  </Button>
                </Popconfirm>
              </Space>
            </Space>
          </Form>
        </Formik>
      </Space>
    </div>
  );
}
