import moment from "moment";

export const formatDate = function (timestamp, format = "HH:mm DD/MM/YYYY") {
  const t = moment(timestamp);
  return t.format(format);
};

export const statusLabel = (status) => {
  switch (status) {
    case "active":
      return "Hoạt động";
    case "block":
      return "Bị khóa";
    default:
      return "N/A";
  }
};

export const bookStatus = (status) => {
  switch (status) {
    case "waiting":
      return "Chưa đón";
    case "done":
      return "Đã đón";
    case "cancel":
      return "Đã hủy";
    case "all":
      return "Tất cả";
    default:
      return "none";
  }
};

export const billStatus = (status) => {
  switch (status) {
    case "active":
      return "Đang gửi";
    case "done":
      return "Hoàn thành";
      case "all":
        return "Tất cả";
    default:
      return "none";
  }
};
