import {
  CopyOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { bookStatus, formatDate } from "../../helper/helper";
import axiosClient from "../../network/RestClient";
import DetailPage from "./detail";
const { Text, Paragraph } = Typography;

export default function ListPage() {
  const [loading, setLoading] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [model, setModel] = useState(null);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("waiting");

  // phan trang
  const [limitOffset, setLimitOffset] = useState({
    limit: 20,
    offset: 0,
  });
  const [totalResult, setTotalResult] = useState(1);
  const fetchData = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/agency/booking/search", {
        params: {
          search: search,
          limit: limitOffset.limit,
          offset: limitOffset.offset,
          filter: filter,
          sortBy: filter === "waiting" ? "book_date_asc" : "book_date_desc",
        },
      });
      if (res && res.status === 0) {
        setList((old) => [...res.data]);
        setTotalResult(res.totalResult);
      } else {
        message.error(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, [search, limitOffset, filter]);

  return (
    <div className="d-flex flex-column align-items-center h-100 w-100 p-1">
      <Modal
        title={"Lịch hẹn gửi"}
        closable="true"
        visible={showPopup}
        cancelText="Đóng"
        okText="Lưu lại"
        footer={[]}
        onCancel={() => {
          setShowPopup(false);
          setModel(null);
        }}
      >
        <DetailPage
          model={model}
          onDone={() => {
            setModel(null);
            setShowPopup(false);
            fetchData();
          }}
          onRemove={() => {
            setModel(null);
            setShowPopup(false);
            fetchData();
          }}
        />
      </Modal>
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Space direction="horizontal" size="middle">
          <Space direction="horizontal" size="small">
            <Input.Search
              placeholder="Tìm theo biển số xe, số điện thoại"
              loading={loading}
              enterButton={true}
              name="search"
              value={search}
              allowClear={true}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              width={{ xs: 200, sm: 400, md: 400, lg: 400 }}
            />
            <Dropdown
              overlay={
                <Menu
                  onClick={(e) => {
                    setFilter(e.key);
                  }}
                  selectedKeys={[filter]}
                  items={[
                    {
                      label: "Tất cả",
                      key: "all",
                    },
                    {
                      label: "Chưa đón",
                      key: "waiting",
                    },
                    {
                      label: "Đã đón",
                      key: "done",
                    },
                    {
                      label: "Đã hủy",
                      key: "cancel",
                    },
                  ]}
                />
              }
            >
              <Button>
                <Space>
                  {filter ? bookStatus(filter) : "Tình trạng lịch"}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Space>
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={(e) => setShowPopup(true)}
            style={{ marginLeft: "auto" }}
          >
            Tạo
          </Button>
        </Space>

        <Table
          bordered={true}
          rowKey="_id"
          title={() => (
            <Row justify="center">
              <Text strong mark>
                {"Tổng số " + bookStatus(filter) + " : " + totalResult}
              </Text>
            </Row>
          )}
          style={{
            width: "100%",
            overflowX: "auto",
          }}
          size="small"
          loading={loading}
          dataSource={list}
          columns={[
            {
              title: "Số điện thoại",
              dataIndex: "customerPhone",
              key: "customerPhone",
              align: "center",
              render: (text, item) => <Text strong>{text}</Text>,
            },
            {
              title: "Giờ hẹn",
              dataIndex: "bookDate",
              key: "bookDate",
              align: "center",
              responsive: ["sm"],
              render: (text) => formatDate(text, "HH:mm DD/MM"),
            },
            {
              title: "Giờ hẹn",
              dataIndex: "bookDate",
              key: "bookDate",
              align: "center",
              responsive: ["xs"],
              render: (text, item) => (
                <div>
                  <Text>{formatDate(text, "HH:mm DD/MM")}</Text>
                  {/* <Tag color={item?.status ==="done" ? 'success' : 'orange' }>{bookStatus(item?.status)}</Tag> */}
                </div>
              ),
            },
            {
              title: "Tình trạng",
              dataIndex: "status",
              key: "status",
              align: "center",
              responsive: ["sm"],
              render: (text) => {
                switch (text) {
                  case "waiting":
                    return (
                      <Text mark strong>
                        {bookStatus(text)}
                      </Text>
                    );
                  case "done":
                    return <Text type="secondary">{bookStatus(text)}</Text>;
                  default:
                    return <Text>{bookStatus(text)}</Text>;
                }
              },
            },
            {
              title: "Hành động",
              dataIndex: "",
              align: "center",
              responsive: ["sm"],
              render: (text, item) => (
                <Space direction="horizontal">
                  <Button
                    type="link"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                      setModel(item);
                      setShowPopup(true);
                    }}
                  >
                    Sửa
                  </Button>
                  <Button
                    type="link"
                    shape="circle"
                    icon={<CopyOutlined />}
                    onClick={(e) => {
                      const mess = buildMessage(item);
                      navigator.clipboard.writeText(mess);
                      message.info("Đã copy nội dung: \n" + mess);
                    }}
                  >
                    Copy
                  </Button>
                </Space>
              ),
            },
            {
              title: "Thao tác",
              dataIndex: "",
              align: "center",
              responsive: ["xs"],
              render: (text, item) => (
                <div className="d-flex flex-row">
                  <Button
                    type="link"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                      setModel(item);
                      setShowPopup(true);
                    }}
                  />
                  <Button
                    type="link"
                    shape="circle"
                    icon={<CopyOutlined />}
                    onClick={(e) => {
                      const mess = buildMessage(item);
                      navigator.clipboard.writeText(mess);
                      message.info("Đã copy nội dung: \n" + mess);
                    }}
                  />
                </div>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <Text>{record.customerName}</Text>
                <p style={{ margin: 0 }}>{record.note}</p>
              </div>
            ),
            rowExpandable: (record) => record.note || record.customerName,
          }}
          pagination={{
            current: limitOffset.offset / limitOffset.limit + 1,
            position: ["bottomCenter"],
            total: totalResult,
            showSizeChanger: true,
            pageSize: limitOffset.limit,
            hideOnSinglePage: false,
            onChange: (pIndex, pSize) => {
              setLimitOffset({ limit: pSize, offset: (pIndex - 1) * pSize });
            },
          }}
        />
      </Space>
    </div>
  );
}

function buildMessage(item) {
  return (
    "SĐT Khách:  " +
    item.customerPhone +
    "\nNgày đến: " +
    formatDate(item.bookDate) +
    "\nGhi chú: " +
    item.note
  );
}
