import {
  AntDesignOutlined,
  CalendarOutlined,
  CarOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  message,
  Row,
  Space,
  Statistic,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { adminAtom } from "../../data/admin_atoms";
import axiosClient, { clearAxiosToken } from "../../network/RestClient";

const { Text } = Typography;

export default function UserPage() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [admin, setAdmin] = useRecoilState(adminAtom);
  const navigate = useNavigate();
  const logout = () => {
    clearAxiosToken();
    setAdmin(null);
    navigate("/login");
  };

  const fetchData = async function () {
    setLoading(true);
    try {
      const fromDate = new Date();
      fromDate.setUTCHours(0, 0, 0, 0);
      const toDate = new Date();
      toDate.setUTCHours(23, 59, 59, 999);
      const stepTime = 7 * 3600;
      const res = await axiosClient.get("/agency/dashboard", {
        params: {
          fromDate: fromDate.getTime() / 1000 - stepTime,
          toDate: toDate.getTime() / 1000 - stepTime,
        },
      });
      if (res && res.status === 0) {
        setData(res.data);
      } else {
        message.error(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <div className="d-flex flex-column align-items-center h-100 p-4 w-100">
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", alignItems: "center", width: "100%" }}
      >
        <Space
          direction="horizontal"
          size="middle"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Avatar
            size={{ xs: 12, sm: 20, md: 30, lg: 40, xl: 50, xxl: 50 }}
            icon={<AntDesignOutlined />}
            src={admin?.thumb || ""}
          />
          <Space
            direction="vertical"
            size="small"
            style={{ display: "flex", alignItems: "start" }}
          >
            <Text>{admin?.name}</Text>
            <Button type="default" size="small" onClick={(e) => logout()}>
              Đăng xuất
            </Button>
          </Space>
        </Space>
        <Row gutter={10}>
          <Card>
            <Statistic
              prefix={<CarOutlined />}
              title="Xe vào hôm nay"
              value={data?.in_today || 0}
            />
          </Card>
          <Card>
            <Statistic
              prefix={<LogoutOutlined />}
              title="Xe ra hôm nay"
              value={data?.out_today || 0}
            />
          </Card>
          <Card>
            <Statistic
              prefix={<CalendarOutlined />}
              title="Hẹn gửi đã đón"
              value={data?.book_done_today || 0}
              suffix={`/${data?.book_today || 0}`}
            />
          </Card>
        </Row>
      </Space>
    </div>
  );
}
