import {
  CalendarOutlined,
  CarOutlined,
  ClockCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Menu, message, Skeleton, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { adminAtom } from "../../data/admin_atoms";
import axiosClient from "../../network/RestClient";
const { Title } = Typography;

export default function HomePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState("Trang chủ");

  const menus = [
    {
      label: "Trang chủ",
      key: "",
      icon: <HomeOutlined />,
      keypath: "/",
    },
    {
      label: "Xe vào/ra",
      key: "in-out",
      icon: <CarOutlined />,
      keypath: "/in-out",
    },
    {
      label: "Lịch hẹn",
      key: "booking",
      icon: <CalendarOutlined />,
      keypath: "/booking",
    },
    {
      label: " Tính giờ",
      key: "caculate",
      icon: <ClockCircleOutlined />,
      keypath: "/caculate",
    },
  ];

  const [selectedTabs, setSelectedTabs] = useState([]);
  useEffect(() => {
    menus.forEach((el, index) => {
      try {
        if (location.pathname.startsWith(el.keypath)) {
          setSelectedTabs([el.key]);
          setTitle(el.label);
        }
      } catch (err) {
        console.log(err.message);
      }
    });
    return () => {};
  }, [location]);

  const [loading, setLoading] = useState("");
  const setAdminAtom = useSetRecoilState(adminAtom);

  const fetchProfile = async function () {
    const goToLogin = () => {
      navigate("/login", { replace: true });
    };

    setLoading(true);
    try {
      const token = localStorage.getItem("auth_token");
      if (token) {
        const rs = await axiosClient.get("/agency/profile", {});
        if (rs && rs.status === 0) {
          setLoading(false);
          setAdminAtom((old) => rs.data);
        } else {
          if (rs.statusCode === 463) {
            message.error("Phiên đăng nhập cũ đã hết hạn!");
          } else {
            message.error(rs?.message ?? "Yêu cầu đăng nhập");
          }
          goToLogin();
        }
      } else {
        goToLogin();
      }
    } catch (err) {
      console.log(err);
      goToLogin();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchProfile();
    }, 100);
    return () => {};
  }, []);

  return (
    <div className="w-100 p-sm-10 p-xs-2">
      <Menu
        className="d-flex justify-content-center align-items-center"
        mode="vertical"
        selectedKeys={selectedTabs}
        selectable={true}
        theme="dark"
        onClick={(e) => {
          navigate("/" + e.keyPath);
        }}
        items={menus}
        style={{
          width: "100%",
          minWidth: "400px",
          alignSelf: "center",
          padding: "0px",
        }}
      />
      <Space
        direction="vertical"
        size="small"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="d-flex flex-row justify-content-center align-items-center mt-1">
          <Title level={3}>{title}</Title>
        </div>
        {loading ? <Skeleton /> : <Outlet />}
      </Space>
    </div>
  );
}
