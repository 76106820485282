import axios from "axios";
import queryString from "query-string";
import { useResetRecoilState } from "recoil";
import { adminAtom } from "../data/admin_atoms";

const axiosClient = axios.create({
  baseURL: "https://api.guixenoibai.vn/",
  // baseURL: "http://localhost:3000/",
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.defaults.timeout = 15 * 1000;

axiosClient.interceptors.request.use(
  async function (config) {
    const appKey = buildToken();
    const token = localStorage.getItem("auth_token");
    config.headers["Application-Key"] = appKey;
    config.headers["Authorization"] = token;
    return config;
  },
  function (error) {}
);

axiosClient.interceptors.response.use(
  function (response) {
    const code = response.data.statusCode;
    if ([463, 469].includes(code)) {
      // xóa thông tin admin
      // xóa header
      // chuyển về trang đăng nhập
      try {
        setTimeout(() => {
          clearAxiosToken();
          window.location.href = "/login";
          useResetRecoilState(adminAtom);
        }, 2000);
      } catch (err) {}
    }
    return response.data;
  },
  function (error) {}
);

function buildToken() {
  return "123";
  // try {
  //   const key = "vngJBUBUbrbt9986cefr";
  //   const date = new Date().getDate().toLocaleString("en-US", {
  //     timeZone: "Asia/Saigon",
  //   });
  //   const signObject = {
  //     key: key,
  //     time: date,
  //   };
  //   const jsonSign = JSON.stringify(signObject);
  //   const hash = md5(jsonSign.toString());
  //   const hasObject = {
  //     sign: hash,
  //     time: 1,
  //   };
  //   const jsonHash = JSON.stringify(hasObject);
  //   const rs = base64.encode(jsonHash);
  //   return rs;
  // } catch (e) {
  //   console.log(e.stack);
  //   return "";
  // }
}

export const genToken = buildToken();

export const clearAxiosToken = () => {
  axiosClient.defaults.headers["Authorization"] = "";
  localStorage.removeItem("auth_token");
};

export const udpateAxiosToken = (token) => {
  axiosClient.defaults.headers["Authorization"] = token;
  localStorage.setItem("auth_token", token);
};

export default axiosClient;
