import { Divider, message, Space, Typography } from "antd";
import { Formik } from "formik";
import { DatePicker, Form, SubmitButton, TimePicker } from "formik-antd";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { formatDate } from "../../helper/helper";
import axiosClient from "../../network/RestClient";
import locale from "antd/lib/locale/vi_VN";

const { Text } = Typography;

export default function CheckOutPage({ form, model, onDone }) {
  const PRICECAR = 50000;
  const PRICEBOT = 20000;

  const [rsDate, setRsDate] = useState("0 ngày");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [moneyPerDay, setMoneyPerDay] = useState(60000);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const caculate = () => {
      const fromDate = new Date(model?.fromDate);
      const toDate = new Date(model?.toDate || Date.now());
      const differentHours = (toDate - fromDate) / (1000 * 60 * 60);
      const days = Math.floor(differentHours / 24);
      const hours = Math.floor(differentHours % 24);
      setRsDate(`${days} ngày ${hours} giờ`);

      // tinh tổng ngày
      if (hours >= 12) {
        setTotalDays(days + 0.5);
      } else {
        setTotalDays(days + 0);
      }
      // tinh giá tiền mỗi ngày
      if (days >= 5) {
        setMoneyPerDay(50000);
      } else {
        setMoneyPerDay(60000);
      }

      // tính giá tiền theo số ngày
      setTotalPrice(
        estimatePrice(days, hours, model?.serviceCar, model?.serviceBot)
      );
    };

    const estimatePrice = (days, hours, car, bot) => {
      let total = 0;
      total += days * moneyPerDay;

      // nhỏ hơn nửa ngày thì miễn phí
      // lơn hơn nửa ngày thì tính 0.5 ngày
      if (hours >= 12) {
        total += moneyPerDay * 0.5;
      } else {
        total += moneyPerDay * 0;
      }

      total += car ? PRICECAR : 0;
      total += bot ? PRICEBOT : 0;

      return total;
    };

    caculate();
  }, [model]);

  const update = async function (status, values, actions) {
    actions.setSubmitting(true);
    try {
      const res = await axiosClient.post("/agency/bill/update", {
        id: model?._id,
        customerName: model?.customerName || "",
        customerPhone: model?.customerPhone || "",
        carPlate: model?.carPlate || "Chưa nhập",
        fromDate: model?.fromDate || Date.now(),
        toDate: status === "active" ? null : values.toDate,
        serviceCar: model?.serviceCar || false,
        serviceBot: model?.serviceBot || false,
        note: model?.note || "",
        status: status || "active", // active/ done
      });
      if (res && res.status === 0) {
        onDone();
      } else {
        message.error(res.message ?? "Không thể cập nhật");
      }
    } catch (err) {
      message.error(err.message);
    }
    actions.setSubmitting(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          toDate: model?.toDate || Date.now(),
        }}
        onSubmit={(values, actions) => {
          let status = "active";
          if (model?.status === "active") {
            status = "done";
          } else {
            status = "active";
          }
          update(status, values, actions);
        }}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        <Form>
          <Space
            direction="vertical"
            size="small"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Text type="secondary">
              Biển số xe: <Text strong>{model?.carPlate || ""}</Text>
            </Text>
            <div className="d-flex flex-row justify-content-between">
              <Text type="secondary">
                Tên khách:{" "}
                <Text strong>{model?.customerName || "Không có"}</Text>
              </Text>
              <Text type="secondary">
                Số ĐT: <Text strong>{model?.customerPhone || "Không có"}</Text>
              </Text>
            </div>

            <Text type="secondary">
              Ghi chú: <Text>{model?.note || "Không có"}</Text>
            </Text>
            <Text type="secondary">
              Ngày vào gửi:{" "}
              <Text mark strong>
                {formatDate(model?.fromDate || "")}
              </Text>
            </Text>
            <Text type="secondary">
              Ngày lấy xe ra:{" "}
              <Space
                direction="horizontal"
                size="middle"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TimePicker
                  name="toDate"
                  locale={locale}
                  format="HH:mm"
                  style={{ width: "100%", marginTop: 0 }}
                  placeholder="Giờ đến"
                  inputReadOnly={true}
                  bordered={model?.status === "active"}
                  disabled={model?.status === "done"}
                  size="middle"
                />
                <DatePicker
                  name="toDate"
                  locale={locale}
                  format="DD/MM/YYYY"
                  style={{ width: "100%", marginTop: 0 }}
                  placeholder="Ngày đến"
                  inputReadOnly={true}
                  bordered={model?.status === "active"}
                  disabled={model?.status === "done"}
                  size="middle"
                  showTime={false}
                />
              </Space>
            </Text>

            <Divider
              dashed
              style={{
                margin: 0,
              }}
            />
            <Text strong>Dịch vụ</Text>
            <div className="d-flex flex-row justify-content-between">
              <Text type="secondary">Ô tô đưa đón: </Text>
              {hasService(
                model?.serviceCar,
                <NumberFormat
                  label=""
                  value={PRICECAR}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" đ"
                  width={100}
                />
              )}
            </div>
            <div className="d-flex flex-row justify-content-between">
              <Text type="secondary">Phí cầu đường: </Text>
              {hasService(
                model?.serviceBot,
                <NumberFormat
                  label=""
                  value={PRICEBOT}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" đ"
                  width={100}
                />
              )}
            </div>
            <div className="d-flex flex-row justify-content-between">
              <Text type="secondary">Số ngày gửi:</Text>
              <Text strong>{rsDate || "0 ngày"}</Text>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <Text type="secondary">Mỗi ngày thu:</Text>
              <Text strong>
                <NumberFormat
                  label=""
                  value={moneyPerDay}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" đ"
                  width={100}
                />
              </Text>
            </div>
            <Divider
              dashed
              style={{
                margin: 0,
              }}
            />
            <div className="d-flex flex-row justify-content-center mt-3">
              <SubmitButton
                type="primary"
                size="middle"
                style={{
                  width: "200px",
                }}
                danger={model?.status === "done"}
                htmlType="submit"
              >
                {model?.status === "done" ? "Hủy lệnh xe ra" : "Báo xe ra"}
              </SubmitButton>
            </div>
          </Space>
        </Form>
      </Formik>
    </div>
  );
}

// function hasService(value, money) {
//   return value ? <Text strong>{money}</Text> : <Text strong>0đ</Text>;
// }

function hasService(value, money) {
  return value ? <Text strong>Có thu phí</Text> : <Text delete>Không</Text>;
}
