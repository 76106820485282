import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./App.css";
import BookingRouter from "./page/booking";
import CaculatePage from "./page/caculate";
import CarInOutRouter from "./page/carinout";
import HomePage from "./page/home";
import LoginPage from "./page/login";
import Page404 from "./page/page404";
import UserPage from "./page/user";

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<HomePage />}>
            <Route path="/" element={<UserPage />} />
            <Route path="/in-out" element={<CarInOutRouter />} />
            <Route path="/booking" element={<BookingRouter />} />
            <Route path="/caculate" element={<CaculatePage />} />
          </Route>
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
