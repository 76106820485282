import { FileOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Typography } from "antd";
import locale from "antd/lib/locale/vi_VN";
import { Formik } from "formik";
import {
  DatePicker, Form,
  FormItem,
  Input,
  Radio,
  SubmitButton, TimePicker
} from "formik-antd";
import * as Yup from "yup";
import axiosClient from "../../network/RestClient";

const { TextArea } = Input;

export default function DetailPage({ model, onDone, onRemove }) {
  const create = async function (values, actions) {
    actions.setSubmitting(true);
    try {
      const res = await axiosClient.post("/agency/booking/create", {
        customerName: values.customerName || "",
        customerPhone: values.customerPhone || "",
        bookDate: values.bookDate || Date.now(),
        note: values.note || "",
        status: values.status || "waiting",
      });
      if (res && res.status === 0) {
        onDone();
      } else {
        message.error(res.message ?? "Không thể cập nhật");
      }
    } catch (err) {
      message.error(err.message);
    }
    actions.setSubmitting(false);
  };

  const update = async function (values, actions) {
    actions.setSubmitting(true);
    try {
      const res = await axiosClient.post("/agency/booking/update", {
        id: model?._id,
        customerName: values.customerName || "",
        customerPhone: values.customerPhone || "",
        bookDate: values.bookDate || Date.now(),
        note: values.note || "",
        status: values.status || "waiting",
      });
      if (res && res.status === 0) {
        onDone();
      } else {
        message.error(res.message ?? "Không thể cập nhật");
      }
    } catch (err) {
      message.error(err.message);
    }
    actions.setSubmitting(false);
  };

  const remove = async function () {
    try {
      const res = await axiosClient.get("/agency/booking/delete", {
        params: {
          id: model?._id,
        },
      });
      if (res && res.status === 0) {
        onRemove();
      } else {
        message.error(res.message ?? "Không thể xóa");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <div>
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Formik
          initialValues={{
            customerName: model?.customerName || "",
            customerPhone: model?.customerPhone || "",
            bookDate: model?.bookDate || Date.now(),
            note: model?.note || "",
            status: model?.status || "waiting", // waiting/done/cancel
          }}
          onSubmit={(values, actions) => {
            message.info(values);
            if (model) {
              update(values, actions);
            } else {
              create(values, actions);
            }
          }}
          validationSchema={Yup.object({
            customerPhone: Yup.string()
              .min(8, "Nhập sai số điện thoại")
              .required("Bắt buộc nhập"),
          })}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          <Form>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <FormItem
                name="customerPhone"
                required={true}
                style={{ padding: 0, margin: 0 }}
              >
                <Input
                  name="customerPhone"
                  size="middle"
                  type="tel"
                  placeholder="Số điện thoại"
                  prefix={<PhoneOutlined />}
                  autoCorrect={false}
                  autoComplete={false}
                  autoFocus={true}
                />
              </FormItem>
              <Input
                name="customerName"
                size="middle"
                placeholder="Tên khách"
                prefix={<UserOutlined />}
                autoCorrect={false}
                autoComplete={false}
                autoFocus={false}
              />
              <Space
                direction="horizontal"
                size="middle"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TimePicker
                  name="bookDate"
                  locale={locale}
                  format="HH:mm"
                  style={{ width: "100%", marginTop: 0 }}
                  placeholder="Giờ đến"
                  inputReadOnly={true}
                  bordered="true"
                  size="middle"
                  showTime={false}
                />
                <DatePicker
                  name="bookDate"
                  locale={locale}
                  format="DD/MM/YYYY"
                  style={{ width: "100%", marginTop: 0 }}
                  placeholder="Ngày đến"
                  inputReadOnly={true}
                  bordered="true"
                  size="middle"
                  showTime={false}
                />
              </Space>
              <TextArea
                name="note"
                size="middle"
                placeholder="Ghi chú"
                prefix={<FileOutlined />}
                maxLength={200}
                rows={3}
                autoCorrect={false}
                autoComplete={false}
                autoFocus={false}
              />
              <Radio.Group name="status" size="middle">
                <Space direction="vertical">
                  <Radio value={"waiting"} size="middle">
                    Chưa đón khách về
                  </Radio>
                  <Radio value={"done"} size="middle">
                    Đã đón khách về
                  </Radio>
                  <Radio value={"cancel"} size="middle">
                    Khách hủy hẹn gửi
                  </Radio>
                </Space>
              </Radio.Group>
              <Space
                direction="horizontal"
                size="middle"
                align="center"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <SubmitButton size="middle" htmlType="submit">
                  Lưu lại
                </SubmitButton>
                <Popconfirm
                  title="Có chắc chắn xóa?"
                  onConfirm={() => remove()}
                >
                  <Button size="middle" htmlType="button" danger={true}>
                    Xóa bỏ
                  </Button>
                </Popconfirm>
              </Space>
            </Space>
          </Form>
        </Formik>
      </Space>
    </div>
  );
}
