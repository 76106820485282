import { Space, Typography } from "antd";
import locale from "antd/lib/locale/vi_VN";
import { Formik } from "formik";
import { DatePicker, Form, SubmitButton, TimePicker } from "formik-antd";
import { useState } from "react";

const { Text } = Typography;

export default function CaculatePage() {
  const [rsDate, setRsDate] = useState("0 ngày");
  const caculate = (values, actions) => {
    actions.setSubmitting(true);
    const fromDate = new Date(values.fromDate);
    const toDate = new Date(values.toDate);
    const differentHours = (toDate - fromDate) / (1000 * 60 * 60);
    const days = Math.floor(differentHours / 24);
    const hours = Math.floor(differentHours % 24);
    setRsDate(`${days} ngày ${hours} giờ`);
    actions.setSubmitting(false);
  };



  return (
    <Formik
      initialValues={{
        fromDate: Date.now() - (1000 * 60 * 60 * 24 * 1),
        toDate: Date.now(),
      }}
      onSubmit={(values, actions) => {
        caculate(values, actions);
      }}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize={false}
    >
      <Form>
        <div className="d-flex flex-column align-items-center h-100 p-3">
          <Space
            direction="vertical"
            size="middle"
            style={{ display: "flex", alignItems: "start" }}
          >
            <Text>Ngày đến gửi</Text>
            <Space
              direction="horizontal"
              size="middle"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TimePicker
                name="fromDate"
                locale={locale}
                format="HH:mm"
                style={{ width: "100%", marginTop: 0 }}
                placeholder="Giờ đến"
                inputReadOnly={true}
                bordered="true"
                size="middle"
                showTime={false}
              />
              <DatePicker
                name="fromDate"
                locale={locale}
                format="DD/MM/YYYY"
                style={{ width: "100%", marginTop: 0 }}
                placeholder="Ngày đến"
                inputReadOnly={true}
                bordered="true"
                size="middle"
                showTime={false}
              />
            </Space>

            <Text>Ngày lấy xe đi</Text>
            <Space
              direction="horizontal"
              size="middle"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TimePicker
                name="toDate"
                locale={locale}
                format="HH:mm"
                style={{ width: "100%", marginTop: 0 }}
                placeholder="Giờ đến"
                inputReadOnly={true}
                bordered="true"
                size="middle"
                showTime={false}
              />
              <DatePicker
                name="toDate"
                locale={locale}
                format="DD/MM/YYYY"
                style={{ width: "100%", marginTop: 0 }}
                placeholder="Ngày đến"
                inputReadOnly={true}
                bordered="true"
                size="middle"
                showTime={false}
              />
            </Space>
            <div></div>
            <SubmitButton size="middle" htmlType="submit">
              Tính ngày
            </SubmitButton>
            <Space direction="horizontal" size="middle">
              <Text>Số ngày:</Text>
              <Text mark strong style={{ fontSize: 20, color: "red" }}>
                {rsDate}
              </Text>
            </Space>
          </Space>
        </div>
      </Form>
    </Formik>
  );
}
