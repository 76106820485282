import {
  CalendarOutlined,
  CarOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  GatewayOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { billStatus, formatDate } from "../../helper/helper";
import axiosClient from "../../network/RestClient";
import CheckOutPage from "./checkout";
import DetailPage from "./detail";
const { Text, Paragraph } = Typography;

export default function CarInOutPage() {
  const [loading, setLoading] = useState(false);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showCheckOutPopup, setShowCheckOutPopup] = useState(false);
  const [model, setModel] = useState(null);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("active");

  // phan trang
  const [limitOffset, setLimitOffset] = useState({
    limit: 20,
    offset: 0,
  });
  const [totalResult, setTotalResult] = useState(1);

  const fetchData = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/agency/bill/search", {
        params: {
          search: search,
          limit: limitOffset.limit,
          offset: limitOffset.offset,
          filter: search ? "all" : filter,
          sortBy: filter === "done" ? "to_date_desc" : "from_date_desc",
        },
      });
      if (res && res.status === 0) {
        setList((old) => [...res.data]);
        setTotalResult(res.totalResult);
      } else {
        message.error(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, [search, limitOffset, filter]);
  return (
    <div className="d-flex flex-column align-items-center h-100 w-100 p-1">
      <Modal
        title={model ? "Chỉnh sửa" : "Xem mới vào"}
        closable="true"
        visible={showEditPopup}
        cancelText="Đóng"
        okText="Lưu lại"
        footer={[]}
        onCancel={() => {
          setModel(null);
          setShowEditPopup(false);
        }}
      >
        <DetailPage
          model={model}
          onDone={() => {
            setModel(null);
            setShowEditPopup(false);
            fetchData();
          }}
          onRemove={() => {
            setModel(null);
            setShowEditPopup(false);
            fetchData();
          }}
        />
      </Modal>
      <Modal
        title={"Xe ra khỏi bãi"}
        closable="true"
        visible={showCheckOutPopup}
        cancelText="Đóng"
        okText="Lưu lại"
        footer={[]}
        onCancel={() => {
          setModel(null);
          setShowCheckOutPopup(false);
        }}
      >
        <CheckOutPage
          model={model}
          onDone={() => {
            setModel(null);
            setShowCheckOutPopup(false);
            fetchData();
          }}
        />
      </Modal>
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Space direction="horizontal" size="middle">
          <Space direction="horizontal" size="small">
            <Input.Search
              placeholder="Tìm biển số, SĐT"
              loading={loading}
              enterButton={true}
              name="search"
              value={search}
              allowClear={true}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              width={{ xs: 200, sm: 400, md: 400, lg: 400 }}
            />
            <Dropdown
              overlay={
                <Menu
                  onClick={(e) => {
                    setFilter(e.key);
                  }}
                  selectedKeys={[filter]}
                  items={[
                    {
                      label: "Tất cả",
                      key: "all",
                    },
                    {
                      label: "Đang gửi",
                      key: "active",
                    },
                    {
                      label: "Hoàn thành",
                      key: "done",
                    },
                  ]}
                />
              }
            >
              <Button>
                <Space>
                  {filter ? billStatus(filter) : "Tình trạng lịch"}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Space>
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={(e) => setShowEditPopup(true)}
            style={{ marginLeft: "auto" }}
          >
            Tạo
          </Button>
        </Space>

        <Table
          style={{
            width: "100%",
            overflowX: "auto",
          }}
          title={(currentPageData) => (
            <Row justify="center">
              <Text strong mark style={{ fontSize: "16px" }}>
                {billStatus(filter) + " : " + totalResult + " xe"}
              </Text>
            </Row>
          )}
          rowKey="_id"
          bordered={true}
          size="small"
          loading={loading}
          dataSource={list}
          columns={[
            {
              title: "STT",
              dataIndex: "",
              key: "stt",
              align: "center",
              render: (text, item, index) => index + 1,
              responsive: ["sm"],
            },
            {
              title: "Biển số",
              dataIndex: "carPlate",
              key: "carPlate",
              align: "center",
              responsive: ["sm"],
              render: (text) => <Text strong>{text}</Text>,
            },
            {
              title: "Ngày vào",
              dataIndex: "fromDate",
              key: "fromDate",
              align: "center",
              responsive: ["sm"],
              render: (text) => formatDate(text, "HH:mm DD/MM"),
            },
            {
              title: filter === "active" ? "Tạm tính" : "Ngày ra",
              dataIndex: "toDate",
              key: "toDate",
              align: "center",
              responsive: ["sm"],
              render: (text, item) => {
                if (item.toDate) {
                  return text ? formatDate(text, "HH:mm DD/MM") : "+";
                } else {
                  const fromDate = new Date(item?.fromDate);
                  const toDate = new Date(Date.now());
                  const differentHours = (toDate - fromDate) / (1000 * 60 * 60);
                  const days = Math.floor(differentHours / 24);
                  const hours = Math.floor(differentHours % 24);
                  return (
                    <Text type="danger">{`${days} ngày ${hours} giờ`}</Text>
                  );
                }
              },
            },
            {
              title: "Phí ô tô",
              dataIndex: "serviceCar",
              key: "serviceCar",
              align: "center",
              responsive: ["sm"],
              render: (text) => <Checkbox checked={text} />,
            },
            {
              title: "Phí soát vé",
              dataIndex: "serviceBot",
              key: "serviceBot",
              align: "center",
              responsive: ["sm"],
              render: (text) => <Checkbox checked={text} />,
            },
            {
              title: "Trạng thái",
              dataIndex: "status",
              key: "status",
              align: "center",
              responsive: ["sm"],
              render: (text) => {
                switch (text) {
                  case "done":
                    return <Tag color="purple">Hoàn thành</Tag>;
                  default:
                    return <Tag color="orange">Đang gửi</Tag>;
                }
              },
            },
            {
              title: "Hành động",
              dataIndex: "",
              align: "center",
              responsive: ["sm"],
              render: (text, item) => (
                <Col>
                  <Button
                    type="link"
                    shape="circle"
                    icon={<EyeOutlined />}
                    onClick={(e) => {
                      setModel(item);
                      setShowCheckOutPopup(true);
                    }}
                  >
                    Xem
                  </Button>
                  <Button
                    type="link"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                      setModel(item);
                      setShowEditPopup(true);
                    }}
                  >
                    Sửa
                  </Button>
                </Col>
              ),
            },

            // mobile
            {
              title: "Biển số",
              dataIndex: "carPlate",
              key: "xscar",
              align: "center",
              responsive: ["xs"],
              render: (text, item) => (
                <div className="d-flex flex-column">
                  <Text strong>{item?.carPlate}</Text>
                </div>
              ),
            },
            {
              title: "Ngày vào/ra",
              dataIndex: "fromDate",
              key: "fromDate",
              align: "center",
              responsive: ["xs"],
              render: (text, item) => {
                if (item.toDate) {
                  return (
                    <div className="d-flex flex-column">
                      <Text>{formatDate(text, "HH:mm DD/MM")}</Text>
                      <Text>{formatDate(item?.toDate, "HH:mm DD/MM")}</Text>
                    </div>
                  );
                } else {
                  const fromDate = new Date(item?.fromDate);
                  const toDate = new Date(Date.now());
                  const differentHours = (toDate - fromDate) / (1000 * 60 * 60);
                  const days = Math.floor(differentHours / 24);
                  const hours = Math.floor(differentHours % 24);
                  return (
                    <div className="d-flex flex-column">
                      <Text>{formatDate(text, "HH:mm DD/MM")}</Text>
                      <Text type="danger">{`${days} ngày ${hours} giờ`}</Text>
                    </div>
                  );
                }
              },
            },
            {
              title: "Thao tác",
              dataIndex: "",
              align: "center",
              responsive: ["xs"],
              render: (text, item) => (
                <div className="d-flex flex-row justify-content-center">
                  <Button
                    type="link"
                    shape="circle"
                    icon={<EyeOutlined />}
                    onClick={(e) => {
                      setModel(item);
                      setShowCheckOutPopup(true);
                    }}
                  />
                  <Button
                    type="link"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                      setModel(item);
                      setShowEditPopup(true);
                    }}
                  />
                </div>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => {
              const fromDate = new Date(record?.fromDate);
              const toDate = new Date(record?.toDate || Date.now());
              const differentHours = (toDate - fromDate) / (1000 * 60 * 60);
              const days = Math.floor(differentHours / 24);
              const hours = Math.floor(differentHours % 24);

              return (
                <div className="d-flex flex-column">
                  <Text>
                    {(record?.customerName || "Không tên") +
                      " - " +
                      (record?.customerPhone || "Không SĐT")}
                  </Text>
                  <Paragraph code strong style={{ margin: 0, padding: 0 }}>
                    {record?.note || "Không ghi chú"}
                  </Paragraph>
                  <Text>
                    <CarOutlined />{" "}
                    {record?.serviceCar ? "Có thu phí ô tô" : " -- "}
                  </Text>
                  <Text>
                    <GatewayOutlined />{" "}
                    {record?.serviceBot
                      ? "Có thu phí qua trạm soát vé"
                      : " -- "}
                  </Text>
                  <Text>
                    <CalendarOutlined className="mt-2" /> Tổng gửi:{" "}
                    <Text strong> {`${days} ngày ${hours} giờ`}</Text>
                  </Text>
                </div>
              );
            },
            rowExpandable: (record) => true,
          }}
          pagination={{
            current: limitOffset.offset / limitOffset.limit + 1,
            position: ["bottomCenter"],
            total: totalResult,
            showSizeChanger: true,
            pageSize: limitOffset.limit,
            hideOnSinglePage: false,
            onChange: (pIndex, pSize) => {
              setLimitOffset({ limit: pSize, offset: (pIndex - 1) * pSize });
            },
          }}
        />
      </Space>
    </div>
  );
}
