import { Route, Routes } from "react-router-dom";
import ListPage from "./list";

export default function BookingRouter() {
  return (
    <Routes>
      <Route path="" element={<ListPage />} />
    </Routes>
  );
}
