import { message } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input, SubmitButton } from "formik-antd";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axiosClient, { udpateAxiosToken } from "../../network/RestClient";

export default function LoginPage() {
  const navigate = useNavigate();

  const login = async function (values, actions) {
    actions.setSubmitting(false);
    try {
      const rs = await axiosClient.post("/agency/login", {
        username: values.username,
        password: values.password,
      });
      if (rs && rs.status === 0) {
        udpateAxiosToken(rs.data.token);
        navigate("/");
      } else {
        message.error(rs?.message ?? rs?.data ?? "Đăng nhập thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    actions.setSubmitting(false);
  };
  return (
    <div className="d-flex w-100 h-100 align-items-center justify-content-center p-5">
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        onSubmit={(values, actions) => {
          login(values, actions);
        }}
        validationSchema={Yup.object({
          username: Yup.string().required("Bắt buộc nhập"),
          password: Yup.string().required("Bắt buộc nhập"),
        })}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        <Form>
          <FormItem label="Tài khoản" name="username" required={true}>
            <Input
              name="username"
              size="large"
              placeholder="Tên đăng nhập"
              allowClear={true}
              autoCapitalize={true}
            />
          </FormItem>
          <FormItem label="Mật khẩu" name="password" required={true}>
            <Input.Password
              name="password"
              size="large"
              placeholder="Mật khẩu"
              allowClear={true}
              autoCapitalize={true}
            />
          </FormItem>
          <div className="d-flex flex-column align-items-center">
            <SubmitButton
              type="primary"
              htmlType="submit"
              style={{
                width: "100px",
              }}
            >
              Đăng nhập
            </SubmitButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
