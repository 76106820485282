import { Route, Routes } from "react-router-dom";
import ListPage from "./list";

export default function CarInOutRouter() {
  return (
    <Routes>
      <Route path="" element={<ListPage />} />
    </Routes>
  );
}
